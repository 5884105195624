<template>
  <div class="maintenance-page text-center">
    <!-- Title -->
    <h1 class="maintenance-page__title q-mt-none q-mb-sm"> {{ title }} </h1>

    <!-- Subtitle -->
    <div class="maintenance-page__subtitle q-mb-xl"> {{ subtitle }} </div>

    <div class="maintenance-page__animated overflow-hidden relative-position q-mb-xl">
      <!--  Mountains -->
      <div class="maintenance-page__mountains flex no-wrap items-end absolute">
        <img
          class="maintenance-page__big-mountain"
          src="@/assets/images/maintenance-page/mountain-big.svg"
          alt="Mountain"
        />
        <img
          class="maintenance-page__small-mountain"
          src="@/assets/images/maintenance-page/mountain-small.svg"
          alt="Small mountain"
        />
      </div>

      <!-- Clouds -->
      <div class="absolute full-width">
        <img
          v-for="cloud in clouds"
          :key="cloud"
          :class="`maintenance-page__cloud--${ cloud }`"
          class="maintenance-page__cloud absolute"
          src="@/assets/images/maintenance-page/cloud.svg"
          alt="Cloud"
        />
      </div>

      <!-- Back trees -->
      <div class="maintenance-page__trees-back absolute full-width">
        <img
          v-for="backTree in backTrees"
          :key="backTree"
          :class="`maintenance-page__tree-back--${ backTree }`"
          class="maintenance-page__tree-back absolute"
          src="@/assets/images/maintenance-page/tree.svg"
          alt="Tree"
        />
      </div>

      <!-- Road -->
      <div class="maintenance-page__road absolute full-width">
        <!-- Truck -->
        <div v-if="type === MAINTENANCE_PAGE_TYPES.NOT_FOUND" class="maintenance-page__truck absolute q-mx-auto">
          <img class="absolute-bottom" src="@/assets/images/maintenance-page/truck.svg" alt="Truck" />
          <img
            v-for="wheel in truckWheels"
            :key="wheel"
            :class="`maintenance-page__truck-wheel--${ wheel }`"
            class="maintenance-page__truck-wheel absolute"
            src="@/assets/images/maintenance-page/wheel.svg"
            alt="Truck wheel"
          />
        </div>

        <!-- Garage -->
        <div v-if="type === MAINTENANCE_PAGE_TYPES.NOT_AUTHORIZED" class="maintenance-page__garage absolute q-mx-auto">
          <img class="absolute-bottom" src="@/assets/images/maintenance-page/garage.svg" alt="Truck" />
          <mi-icon
            class="maintenance-page__garage-gears absolute-bottom"
            name="settings-multi"
            size="32px"
            color="accent"
          ></mi-icon>
        </div>

        <div class="maintenance-page__road-line absolute-top"></div>
      </div>

      <!-- Front tree -->
      <img class="maintenance-page__tree-front absolute" src="@/assets/images/maintenance-page/tree.svg" alt="Tree" />
    </div>

    <!-- Action button slot -->
    <slot name="action-button"></slot>
  </div>
</template>

<script>
  import { MAINTENANCE_PAGE_TYPES } from '@/constants'

  export default {
    name: 'PageLayoutMaintenance',
    props: {
      animated: {
        type: Boolean,
        required: false,
        default: true
      },
      subtitle: {
        type: String,
        required: false,
        default: 'Something went wrong'
      },
      title: {
        type: String,
        required: false,
        default: 'Error'
      },
      type: {
        type: String,
        required: true,
        default: MAINTENANCE_PAGE_TYPES.NOT_FOUND,
        validator: value => Object.values(MAINTENANCE_PAGE_TYPES).includes(value) || !value
      }
    },
    data: () => ({
      MAINTENANCE_PAGE_TYPES,
      backTrees: 3,
      clouds: 6,
      truckWheels: 6
    }),
    watch: {
      animated: {
        handler(current) {
          const animationState = current ? 'running' : 'paused'

          this.$nextTick(() => {
            this.$el?.style?.setProperty('--animation-state', animationState)
          })
        },
        immediate: true
      }
    }
  }
</script>

<style lang="scss">
  $road-height: 46px;
  $road-bottom-position: 10px;

  // Clouds
  $cloud-1-animation-duration: 25s;
  $cloud-2-animation-duration: 21s;
  $cloud-3-animation-duration: 25s;
  $cloud-4-animation-duration: 23s;
  $cloud-5-animation-duration: 26s;
  $cloud-6-animation-duration: 23s;

  $tree-back-animation-duration: 29s;
  $tree-front-animation-duration: 16s;
  $mountain-animation-duration: 40s;
  $road-animation-duration: 220s;
  $wheel-rotation-animation-duration: 1s;

  .maintenance-page {
    --animation-state: paused;

    padding-top: 85px;
  }

  .maintenance-page__title {
    font-size: 8.375rem;
    line-height: 1.2;
  }

  .maintenance-page__subtitle {
    font-size: 1.25rem;
    line-height: 1.2;
  }

  .maintenance-page__animated {
    height: 210px;
  }

  .maintenance-page__mountains {
    animation: mountainMove $mountain-animation-duration linear infinite var(--animation-state);
    animation-delay: -.47 * $mountain-animation-duration;
    bottom: $road-height + $road-bottom-position - 1px;
    will-change: transform;
  }

  .maintenance-page__cloud {
    z-index: 1;
    animation-name: cloudAndTreeMove;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-play-state: var(--animation-state);
    will-change: transform;
  }

  // stylelint-disable selector-class-pattern
  .maintenance-page__cloud--1 {
    top: 15px;
    animation-duration: $cloud-1-animation-duration;
    animation-delay: -.95 * $cloud-1-animation-duration;
  }

  .maintenance-page__cloud--2 {
    top: 65px;
    animation-duration: $cloud-2-animation-duration;
    animation-delay: -.73 * $cloud-2-animation-duration;
  }

  .maintenance-page__cloud--3 {
    top: 15px;
    animation-duration: $cloud-3-animation-duration;
    animation-delay: -.54 * $cloud-3-animation-duration;
  }

  .maintenance-page__cloud--4 {
    top: 28px;
    animation-duration: $cloud-4-animation-duration;
    animation-delay: -.4 * $cloud-4-animation-duration;
  }

  .maintenance-page__cloud--5 {
    top: 0;
    animation-duration: $cloud-5-animation-duration;
    animation-delay: -.26 * $cloud-5-animation-duration;
  }

  .maintenance-page__cloud--6 {
    top: 48px;
    animation-duration: $cloud-6-animation-duration;
    animation-delay: -.17 * $cloud-6-animation-duration;
  }
  // stylelint-enable selector-class-pattern

  .maintenance-page__trees-back {
    bottom: $road-height + $road-bottom-position;
    animation: cloudAndTreeMove $tree-back-animation-duration linear infinite var(--animation-state);
    animation-delay: -.45 * $tree-back-animation-duration;
    z-index: 2;
  }

  .maintenance-page__tree-back {
    bottom: 0;
  }

  // stylelint-disable selector-class-pattern
  .maintenance-page__tree-back--1 {
    width: 50px;
    left: 23%;
  }

  .maintenance-page__tree-back--2 {
    width: 50px;
    right: 37%;
  }

  .maintenance-page__tree-back--3 {
    width: 30px;
    left: 64%;
  }
  // stylelint-enable selector-class-pattern

  .maintenance-page__road {
    bottom: $road-bottom-position;
    height: $road-height;
    background-color: $mi-anthracite-100;
    z-index: 3;
  }

  .maintenance-page__road-line {
    top: calc(50% - 3px);
    height: 3px;
    background-image: linear-gradient(to right, transparent 50%, $mi-white 50%);
    background-size: 30px 100%;
    animation: roadMove $road-animation-duration infinite linear var(--animation-state);
  }

  .maintenance-page__truck {
    left: 0;
    right: 0;
    bottom: 68%;
    width: 234px;
  }

  .maintenance-page__garage {
    left: 0;
    right: 0;
    width: 120px;
  }

  .maintenance-page__garage-gears {
    left: 0;
    right: 0;
    width: 120px;
    bottom: 35px;
  }

  .maintenance-page__truck-wheel {
    bottom: 0;
    animation: wheelRotate $wheel-rotation-animation-duration linear infinite var(--animation-state);
  }

  // stylelint-disable selector-class-pattern
  .maintenance-page__truck-wheel--1 {
    left: 32px;
  }

  .maintenance-page__truck-wheel--2 {
    left: 49px;
  }

  .maintenance-page__truck-wheel--3 {
    left: 66px;
  }

  .maintenance-page__truck-wheel--4 {
    left: 138px;
  }

  .maintenance-page__truck-wheel--5 {
    left: 155px;
  }

  .maintenance-page__truck-wheel--6 {
    left: 202px;
  }
  // stylelint-enable selector-class-pattern

  .maintenance-page__tree-front {
    bottom: 0;
    animation: cloudAndTreeMove $tree-front-animation-duration infinite linear var(--animation-state);
    animation-delay: -.56 * $tree-front-animation-duration;
    z-index: 4;
  }

  @keyframes cloudAndTreeMove {
    from {
      transform: translate(100vw);
    }

    to {
      transform: translate(-100vw);
    }
  }

  @keyframes mountainMove {
    from {
      transform: translateX(100vw);
    }

    to {
      transform: translateX(-100%);
    }
  }

  @keyframes roadMove {
    from {
      background-position: 1000%;
    }

    to {
      background-position: -1000%;
    }
  }

  @keyframes wheelRotate {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>
