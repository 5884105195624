<template>
  <page-layout-maintenance
    :animated="false"
    subtitle="This page could not be found"
    title="404"
    :type="MAINTENANCE_PAGE_TYPES.NOT_FOUND"
  >
    <template #action-button>
      <!-- Back home button -->
      <mi-btn color="accent" to="/"> Back Homepage</mi-btn>
    </template>
  </page-layout-maintenance>
</template>

<script>
  import recordAnalytics from '@/utils/analytics/recordAnalytics'
  import { MAINTENANCE_PAGE_TYPES } from '@/constants'

  import PageLayoutMaintenance from '@/layouts/PageLayoutMaintenance.vue'

  import * as analyticsConstants from '@/utils/analytics/constants'

  export default {
    name: 'NotFound',
    components: { PageLayoutMaintenance },
    data: () => (
      {
        MAINTENANCE_PAGE_TYPES
      }),
    mounted() {
      // Analytics
      recordAnalytics(
        analyticsConstants.NOT_FOUND,
        { redirectedFrom: this.$route?.redirectedFrom }
      )
    }
  }
</script>
